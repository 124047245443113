import styled from "@emotion/styled/";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Masonry from "react-masonry-css";
import Layout from "../../components/layout";

export default function WorkWearGallery() {
  const breakpointColumnsObj = {
    default: 3,
    1000: 2,
    320: 1,
  };
  return (
    <Layout>
      <Container>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <StaticImage
            src="../../assets/img/photo/2021043013618.jpg"
            alt="workwear"
          />

          <StaticImage
            src="../../assets/img/photo/2021043013618.jpg"
            alt="workwear"
          />
          <StaticImage
            src="../../assets/img/photo/2021043013618.jpg"
            alt="workwear"
          />
          <StaticImage
            src="../../assets/img/photo/2021051213867.jpg"
            alt="workwear"
          />
          <StaticImage
            src="../../assets/img/photo/2021043013652.jpg"
            alt="workwear"
          />
          <StaticImage
            src="../../assets/img/photo/2021043013678.jpg"
            alt="workwear"
          />
          <StaticImage
            src="../../assets/img/photo/2021051213284.jpg"
            alt="workwear"
          />
        </Masonry>
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  
  .my-masonry-grid {
    display: flex;
    margin: 0px auto;
    padding:120px 30px 120px 0;
    max-width: 1440px;

    @media (max-width: 768px) {
      padding:120px 0px 120px 0;
    }
    
  }
  .my-masonry-grid_column {
    /* gutter size */
    padding-top: 30px;
    padding-left: 30px;
    background-clip: padding-box;
    
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .my-masonry-grid_column > div {
    background: grey;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
  }
`;
